import React from 'react'
// import { Select } from 'antd'
// import { useTranslation } from 'react-i18next'
// import cn from 'classnames'
// import styles from './style.module.css'
// import { useQueryClient } from 'react-query'

const LanguageSelect: React.FC = () => {
  // const { i18n } = useTranslation()
  // const queryClient = useQueryClient()

  return <></>

  // return (
  //   <div className={cn(styles.container)}>
  //     <Select
  //       className={cn(styles.select)}
  //       value={i18n.language}
  //       onChange={(value) => {
  //         i18n.changeLanguage(value)
  //         queryClient.refetchQueries()
  //       }}
  //       options={[
  //         { value: 'ar', label: 'العربية' },
  //         { value: 'en', label: 'English' },
  //       ]}
  //     />
  //   </div>
  // )
}

export default LanguageSelect
