import styles from './style.module.css'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Card, Col, Row, Spin, Statistic } from 'antd'
import {
  CreditCardOutlined,
  CheckCircleTwoTone,
  TeamOutlined,
  SwapOutlined,
} from '@ant-design/icons'
import { useQuery } from 'react-query'
import axios from 'axios'
import { IStatisticsCounts } from '../../../models/home'
import { useNavigate } from 'react-router-dom'

const StatisticsCards: React.FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const query = useQuery({
    queryKey: ['statistics-counts'],
    queryFn: async () => {
      return axios.get<IStatisticsCounts>('/statistics/counts')
    },
  })

  return (
    <Row gutter={[16, 16]} justify={'center'} align={'middle'}>
      <Col className={styles.col} span={6}>
        <Card
          title={t('home.active_customers')}
          bordered={false}
          style={{ width: '100%', cursor: 'pointer' }}
          onClick={() => navigate('/customer')}
        >
          <Spin spinning={query.isLoading} tip={t('general.loading')}>
            <Statistic
              value={query.data?.data?.activeCustomers}
              valueStyle={{ color: '#12ccaa' }}
              prefix={<TeamOutlined style={{ marginInlineEnd: '.5rem' }} />}
            />
          </Spin>
        </Card>
      </Col>
      <Col className={styles.col} span={6}>
        <Card
          title={t('home.processed_orders')}
          bordered={false}
          style={{ width: '100%', cursor: 'pointer' }}
          onClick={() => navigate('/order')}
        >
          <Spin spinning={query.isLoading} tip={t('general.loading')}>
            <Statistic
              value={query.data?.data?.processedOrders}
              valueStyle={{ color: '#52c41a' }}
              prefix={
                <CheckCircleTwoTone
                  style={{ marginInlineEnd: '.5rem' }}
                  twoToneColor="#52c41a"
                />
              }
            />
          </Spin>
        </Card>
      </Col>
      <Col className={styles.col} span={6}>
        <Card
          title={t('home.paid_orders')}
          bordered={query.isLoading}
          style={{ width: '100%', cursor: 'pointer' }}
          onClick={() => navigate('/order')}
        >
          <Spin spinning={false} tip={t('general.loading')}>
            <Statistic
              value={query.data?.data?.paidOrders}
              valueStyle={{ color: '#12ccaa' }}
              prefix={
                <CreditCardOutlined style={{ marginInlineEnd: '.5rem' }} />
              }
            />
          </Spin>
        </Card>
      </Col>
      <Col className={styles.col} span={6}>
        <Card
          title={t('home.pending_exchange_orders')}
          bordered={query.isLoading}
          style={{ width: '100%', cursor: 'pointer' }}
          onClick={() => navigate('/exchange-order')}
        >
          <Spin spinning={false} tip={t('general.loading')}>
            <Statistic
              value={query.data?.data?.pendingExchangeOrders}
              valueStyle={{ color: '#12ccaa' }}
              prefix={<SwapOutlined style={{ marginInlineEnd: '.5rem' }} />}
            />
          </Spin>
        </Card>
      </Col>
    </Row>
  )
}

export default StatisticsCards
