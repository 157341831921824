import {
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  Row,
  Select,
  Space,
  Table,
} from 'antd'
import { SorterResult } from 'antd/es/table/interface'
import axios from 'axios'
import dayjs from 'dayjs'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import { useNavigate } from 'react-router-dom'

import { EyeOutlined } from '@ant-design/icons'

import HeaderCard from '../../components/common/header-card'
import { IPaginatedList } from '../../models/paginated-list'
import {
  DATE_FORMAT,
  DATE_TIME_FORMAT,
  DEFAULT_PAGE_SIZE,
  DEFAULT_SORT_FUNCTION,
  INITIAL_PAGE,
} from '../../utils/constants'

import type { ColumnsType } from 'antd/es/table'
import { IOrder } from '../../models/order'
import { IIndex, ISort } from '../../models/shared'
import {
  ExchangeOrderStatus,
  IExchangeOrder,
} from '../../models/exchange-order'
import ExchangeOrderStatusTag from '../../components/enums/exchange-order-status-tag'
import ExchangeOrderPaymentMethodTag from '../../components/enums/exchange-order-payment-method-tag'
import { useKeycloak } from '@react-keycloak/web'
import { P_SHOW_EXCHANGE_ORDERS } from '../../utils/rbac/permissions'

const ExchangeOrdersPage: React.FC = () => {
  const {
    keycloak: { hasRealmRole },
  } = useKeycloak()

  const navigate = useNavigate()

  if (!hasRealmRole(P_SHOW_EXCHANGE_ORDERS)) {
    navigate('/403')
  }

  const { t } = useTranslation()

  const [page, setPage] = useState(INITIAL_PAGE)
  const [perPage, setPerPage] = useState(DEFAULT_PAGE_SIZE)
  const [search, setSearch] = useState<string | undefined>()
  const [filter, setFilter] = useState<any>({})
  const [sort, setSort] = useState<ISort | undefined>()

  const query = useQuery({
    queryKey: ['exchange-orders', page, perPage, search, filter, sort],
    queryFn: async () => {
      return axios.get<IPaginatedList<IExchangeOrder>>('/exchange-order', {
        params: {
          page,
          perPage,
          search,
          ...filter,
          sortKey: sort?.name,
          sortDirection: sort?.direction,
        },
      })
    },
  })

  const servicesQuery = useQuery({
    queryKey: ['exchange-services'],
    queryFn: async () => {
      return axios.get<IIndex[]>('/index/exchange-service')
    },
  })

  const columns: ColumnsType<IExchangeOrder> = [
    {
      title: t('exchange_order.email'),
      dataIndex: 'email',
    },
    {
      title: t('exchange_order.service'),
      dataIndex: 'service',
      render: (service) => service.name,
    },
    {
      title: t('exchange_order.status'),
      dataIndex: 'status',
      render: (status) => <ExchangeOrderStatusTag status={status} />,
    },
    {
      title: t('exchange_order.payment_method'),
      dataIndex: 'paymentMethod',
      render: (method) => <ExchangeOrderPaymentMethodTag method={method} />,
    },
    {
      title: t('general.created_at'),
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (dateTime) => (
        <div style={{ width: 150 }}>
          {dayjs(dateTime).format(DATE_TIME_FORMAT)}
        </div>
      ),
      sorter: DEFAULT_SORT_FUNCTION,
    },
    {
      title: t('general.updated_at'),
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      render: (dateTime) => (
        <div style={{ width: 150 }}>
          {dayjs(dateTime).format(DATE_TIME_FORMAT)}
        </div>
      ),
      sorter: DEFAULT_SORT_FUNCTION,
    },
    {
      title: t('general.actions'),
      render: (_, record) => (
        <Space>
          <Button
            type="link"
            icon={<EyeOutlined />}
            onClick={() => navigate(`/exchange-order/${record.id}`)}
          >
            {t('general.show')}
          </Button>
        </Space>
      ),
    },
  ]

  return (
    <>
      <HeaderCard
        title={t('exchange_order.label')}
        onSearch={(value) => {
          setSearch(value.length === 0 ? undefined : value)
        }}
        onFilterChange={(data) => {
          console.log(data)
          setFilter({
            status: data.status,
            currency: data.currency,
            fromDate: data.createdAt
              ? data.createdAt[0]
                ? dayjs(data.createdAt[0]).format(DATE_FORMAT)
                : undefined
              : undefined,
            toDate: data.createdAt
              ? data.createdAt[1]
                ? dayjs(data.createdAt[1]).format(DATE_FORMAT)
                : undefined
              : undefined,
          })
        }}
        filters={
          <Row style={{ padding: '0 1rem' }} gutter={16}>
            <Col span={6}>
              <Form.Item name="status" label={t('exchange_order.status')}>
                <Select
                  allowClear
                  options={[
                    {
                      label: t('exchange_order.status_pending'),
                      value: ExchangeOrderStatus.PENDING,
                    },
                    {
                      label: t('exchange_order.status_rejected'),
                      value: ExchangeOrderStatus.REJECTED,
                    },
                    {
                      label: t('exchange_order.status_accepted'),
                      value: ExchangeOrderStatus.ACCEPTED,
                    },
                    {
                      label: t('exchange_order.status_paid'),
                      value: ExchangeOrderStatus.PAID,
                    },
                  ]}
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item name="serviceId" label={t('exchange_order.service')}>
                <Select
                  loading={servicesQuery.isLoading}
                  allowClear
                  options={servicesQuery.data?.data?.map((i) => {
                    return {
                      label: i.name,
                      value: i.id,
                    }
                  })}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="createdAt" label={t('general.created_at')}>
                <DatePicker.RangePicker style={{ width: '100%' }} />
              </Form.Item>
            </Col>
          </Row>
        }
      />
      <Card bordered={false} bodyStyle={{ padding: 0 }}>
        <Table
          loading={query.isFetching}
          columns={columns}
          dataSource={query.data?.data.data}
          rowKey={(record) => record.id}
          style={{ overflowX: 'scroll' }}
          onChange={(_, __, sorter, ___) => {
            const { columnKey, order } = sorter as SorterResult<IOrder>

            if (!order) {
              setSort(undefined)
            } else {
              setSort({
                name: columnKey as string,
                direction: order === 'ascend' ? 'ASC' : 'DESC',
              })
            }
          }}
          pagination={{
            current: page,
            pageSize: perPage,
            pageSizeOptions: [5, 10, 20, 50, 100],
            showSizeChanger: true,
            total: query.data?.data.pagination.count,
            position: ['bottomCenter'],
            onChange(page, pageSize) {
              setPage(page)
              setPerPage(pageSize)
            },
            showTotal: (total, range) => {
              return `${t('general.showing')} ${range[0]} - ${range[1]} ${t(
                'general.from'
              )} ${total} ${t('general.items')}`
            },
          }}
        />
      </Card>
    </>
  )
}

export default ExchangeOrdersPage
