import Keycloak from 'keycloak-js'

// Setup Keycloak instance as needed
// Pass initialization options as required or leave blank to load from 'keycloak.json'
const keycloak = new Keycloak({
  url: 'https://auth.mygiftcardy.com',
  realm: 'gift-cardy',
  clientId: 'dashboard-client',
})

export default keycloak
