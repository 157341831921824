import React from 'react'
import { Tag } from 'antd'
import { useTranslation } from 'react-i18next'
import { ExchangeOrderStatus } from '../../../models/exchange-order'

interface IProps {
  status: ExchangeOrderStatus
}

const ExchangeOrderStatusTag: React.FC<IProps> = ({ status }) => {
  const { t } = useTranslation()

  let label = ''
  let color = ''

  switch (status) {
    case ExchangeOrderStatus.PENDING:
      label = t('exchange_order.status_pending')
      color = 'default'
      break
    case ExchangeOrderStatus.REJECTED:
      label = t('exchange_order.status_rejected')
      color = 'red'
      break
    case ExchangeOrderStatus.ACCEPTED:
      label = t('exchange_order.status_accepted')
      color = 'green'
      break
    case ExchangeOrderStatus.PAID:
      label = t('exchange_order.status_paid')
      color = 'cyan'
      break
  }

  return <Tag color={color}>{label}</Tag>
}

export default ExchangeOrderStatusTag
