import styles from './style.module.css'
import logo from '../../assets/images/logo-colored.png'
import classNames from 'classnames'

const LoadingPage = () => {
  const commonClasses = classNames(
    styles.animateBounce,
    styles.easeIn,
    styles.rounded
  )

  return (
    <div className={styles.page}>
      <div className={styles.loadingContainer}>
        <div>
          <img src={logo} alt="gift-cardy-logo" height={100} />
        </div>

        <div className={classNames(styles.loader)}>
          <div className={styles.inlineFlex}>
            <div
              className={classNames(commonClasses, styles.animationDelay1)}
            ></div>
            <div
              className={classNames(commonClasses, styles.animationDelay2)}
            ></div>
            <div
              className={classNames(commonClasses, styles.animationDelay3)}
            ></div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default LoadingPage
