import { Modal } from 'antd'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation, useQueryClient } from 'react-query'
import axios from 'axios'

interface IProps {
  orderId: string
  visible: boolean
  setVisible: (visible: boolean) => void
}

const PayExchangeOrderModal: React.FC<IProps> = ({
  orderId,
  visible,
  setVisible,
}) => {
  const { t } = useTranslation()

  const queryClient = useQueryClient()

  const mutation = useMutation({
    mutationFn: () => {
      return axios.post(`/exchange-order/${orderId}/pay`)
    },
    onSuccess() {
      queryClient.invalidateQueries({ queryKey: ['exchange-orders'] })
      queryClient.invalidateQueries({ queryKey: ['exchange-order', orderId] })
      setVisible(false)
    },
  })

  return (
    <Modal
      title={t('exchange_order.pay_exchange_order')}
      open={visible}
      onOk={() => {
        mutation.mutate()
      }}
      okType="primary"
      onCancel={() => setVisible(false)}
      confirmLoading={mutation.isLoading}
    >
      {t('exchange_order.pay_exchange_order_confirmation')}
    </Modal>
  )
}

export default PayExchangeOrderModal
