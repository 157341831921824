export enum ExchangeOrderPaymentMethod {
  CRYPTO = 'CRYPTO',
  PAYPAL = 'PAYPAL',
  PERFECT_MONEY = 'PERFECT_MONEY',
  WEB_MONEY = 'WEB_MONEY',
}

export enum ExchangeOrderStatus {
  PENDING = 'PENDING',
  REJECTED = 'REJECTED',
  ACCEPTED = 'ACCEPTED',
  PAID = 'PAID',
}

export interface IExchangeOrder {
  id: string
  fullName: string
  email: string
  gsm: string
  service: {
    id: number
    name: string
    slug: string
  }
  paymentMethod: ExchangeOrderPaymentMethod
  cryptoMethod?: string
  status: ExchangeOrderStatus
  createdAt: Date
  updatedAt: Date
}

export interface IExchangeOrderLineProduct {
  id: number
  name: string
  voucher: string
  cryptoPrice: number
  paypalPrice: number
  perfectMoneyPrice: number
  webMoneyPrice: number
}

export interface IExchangeOrderLine {
  product: IExchangeOrderLineProduct
  data: any
  image?: string
}

export interface IExchangeOrderDetails extends IExchangeOrder {
  note?: string
  rejectionReason?: string
  lines: IExchangeOrderLine[]
}

export interface IEditExchangeOrder {
  note?: string
  rejectionReason?: string
}
