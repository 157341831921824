import { Descriptions, Modal } from 'antd'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { IExchangeOrderLine } from '../../../../models/exchange-order'

interface IProps {
  line?: IExchangeOrderLine
  visible: boolean
  setVisible: (visible: boolean) => void
}

const ExchangeOrderProductDetailsModal: React.FC<IProps> = ({
  line,
  visible,
  setVisible,
}) => {
  const { t } = useTranslation()

  return (
    <Modal
      title={t('exchange_order.product_details')}
      open={visible}
      onOk={() => setVisible(false)}
      okType="primary"
      onCancel={() => setVisible(false)}
      width={800}
    >
      <Descriptions bordered column={1}>
        <Descriptions.Item label={t('exchange_order.product_name')}>
          {`${line?.product?.name} | ${line?.product?.voucher}`}
        </Descriptions.Item>
        <Descriptions.Item label={t('exchange_order.product_price')}>
          <ul>
            <li>
              <strong>Crypto Price: </strong>
              {line?.product?.cryptoPrice}
            </li>
            <li>
              <strong>PayPal Price: </strong>
              {line?.product?.paypalPrice}
            </li>
            <li>
              <strong>PerfectMoney Price: </strong>
              {line?.product?.perfectMoneyPrice}
            </li>
            <li>
              <strong>WebMoney Price: </strong>
              {line?.product?.webMoneyPrice}
            </li>
          </ul>
        </Descriptions.Item>
        <Descriptions.Item label={t('exchange_order.product_data')}>
          {line?.data && (
            <ul>
              {Object.keys(line?.data).map((prop) => (
                <li>{`${prop}: ${line?.data[prop]}`}</li>
              ))}
            </ul>
          )}
        </Descriptions.Item>
        <Descriptions.Item label={t('exchange_order.product_image')}>
          {line?.image && (
            <img src={line?.image} alt="Product" style={{ width: 600 }} />
          )}
        </Descriptions.Item>
      </Descriptions>
    </Modal>
  )
}

export default ExchangeOrderProductDetailsModal
