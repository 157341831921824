import { Form, FormInstance, Input, Modal } from 'antd'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { IRefundOrder } from '../../../../models/order'
import { useMutation, useQueryClient } from 'react-query'
import axios from 'axios'

interface IProps {
  orderId: string
  visible: boolean
  setVisible: (visible: boolean) => void
}

const RefundOrderModal: React.FC<IProps> = ({
  orderId,
  visible,
  setVisible,
}) => {
  const { t } = useTranslation()

  const queryClient = useQueryClient()

  const formRef = React.useRef<FormInstance>(null)

  const mutation = useMutation({
    mutationFn: (data: IRefundOrder) => {
      return axios.post(`/order/${orderId}/refund`, data)
    },
    onSuccess() {
      queryClient.invalidateQueries({ queryKey: ['orders'] })
      queryClient.invalidateQueries({ queryKey: ['order', orderId] })
      setVisible(false)
    },
  })

  return (
    <Modal
      title={t('order.refund_order')}
      open={visible}
      onOk={() => {
        const data = formRef.current?.getFieldsValue()
        mutation.mutate({
          refundReason: data['refundReason'],
        })
      }}
      onCancel={() => setVisible(false)}
      confirmLoading={mutation.isLoading}
    >
      <Form
        name="form_item_path"
        layout="vertical"
        ref={formRef}
      >
        <Form.Item name="refundReason" label={t('order.refund_reason')}>
          <Input.TextArea />
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default RefundOrderModal
