import { Card, Spin } from 'antd'
import axios from 'axios'
import React, { useState } from 'react'
import { Pie } from 'react-chartjs-2'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import { IExchangeOrdersStatusesOverview } from '../../../../models/home'
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js'

ChartJS.register(ArcElement, Tooltip, Legend)

const ExchangeOrdersStatusesOverview: React.FC = () => {
  const { t } = useTranslation()

  const [data, setData] = useState<any>()

  const query = useQuery({
    queryKey: ['exchange-orders-statuses-overview'],
    queryFn: async () => {
      return axios.get<IExchangeOrdersStatusesOverview>(
        '/statistics/exchange-orders-statuses-overview'
      )
    },
    onSuccess({ data }) {
      const chartData = {
        labels: [
          t('exchange_order.status_pending'),
          t('exchange_order.status_accepted'),
          t('exchange_order.status_rejected'),
          t('exchange_order.status_paid'),
        ],

        datasets: [
          {
            data: [
              data.pendingOrders,
              data.acceptedOrders,
              data.rejectedOrders,
              data.paidOrders,
            ],
            backgroundColor: ['#d9d8ff', '#5cb176', '#ee5e52', '#66b7f1'],
          },
        ],
      }
      setData(chartData)
    },
  })

  return (
    <Spin spinning={query.isLoading}>
      <Card
        title={t('home.exchange_orders_statuses')}
        headStyle={{
          textAlign: 'center',
        }}
        bodyStyle={{
          height: '20rem',
        }}
      >
        {data && (
          <Pie
            data={data}
            options={{
              responsive: true,
              maintainAspectRatio: false,
            }}
          />
        )}
      </Card>
    </Spin>
  )
}

export default ExchangeOrdersStatusesOverview
