import { ConfigProvider, notification as Notification } from 'antd'
import { Locale } from 'antd/es/locale'
import ar from 'antd/locale/ar_EG'
import en from 'antd/locale/en_US'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { QueryClient, QueryClientProvider } from 'react-query'
import { RouterProvider } from 'react-router-dom'
import router from './router'
import './utils/http/axios-instance'
import './utils/localization/i18n'
import { ReactKeycloakProvider } from '@react-keycloak/web'
import keycloak from './keycloak'
import axios from 'axios'
import LoadingPage from './components/loading-page'

const queryClient = new QueryClient()

const App: React.FC = () => {
  const {
    t,
    i18n: { dir, language },
  } = useTranslation()

  const [notification, notificationContextHolder] =
    Notification.useNotification()

  let locale: Locale = ar
  switch (language) {
    case 'en':
      locale = en
      break
  }

  const onError = (error: any) => {
    if (!error) return

    // Unauthenticated
    if (error.response?.status === 401) {
      // keycloak?.logout()
    }

    // Forbidden
    if (error.response?.status === 403) {
      window.location.href = '/403'
    }

    notification.error({
      message: error.response
        ? error.response.data.message
        : error.message ?? `${error}`,
      placement: dir() === 'rtl' ? 'bottomLeft' : 'bottomRight',
    })
  }

  const onSuccess = (data: unknown, error: unknown) => {
    if (error) return
    notification.success({
      message: t('operation_completed_successfully'),
      placement: dir() === 'rtl' ? 'bottomLeft' : 'bottomRight',
    })
  }

  queryClient.setDefaultOptions({
    queries: { onError },
    mutations: { onError, onSettled: onSuccess },
  })

  return (
    <ReactKeycloakProvider
      authClient={keycloak}
      initOptions={{ onLoad: 'login-required', pkceMethod: 'S256' }}
      LoadingComponent={<LoadingPage />}
      onTokens={(tokens) => {
        axios.defaults.headers.common[
          'Authorization'
        ] = `Bearer ${tokens.token}`
      }}
    >
      <QueryClientProvider client={queryClient}>
        <ConfigProvider
          theme={{
            token: {
              colorPrimary: '#12ccaa',
              fontFamily: 'Cairo',
            },
          }}
          direction={dir()}
          locale={locale}
        >
          {notificationContextHolder}
          <RouterProvider router={router} />
        </ConfigProvider>
      </QueryClientProvider>
    </ReactKeycloakProvider>
  )
}

export default App
