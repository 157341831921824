import { Button, Result } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

const ForbiddenPage = () => {
	const { t } = useTranslation()
	const navigate = useNavigate()

	return (
		<div
			style={{
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				width: '100vw',
				height: '100vh'
			}}
		>
			<Result
				status='403'
				title='403'
				subTitle={t('error.forbidden')}
				extra={
					<Button type='primary' onClick={() => navigate('/')}>
						{t('error.back_home')}
					</Button>
				}
			/>
		</div>
	)
}

export default ForbiddenPage
