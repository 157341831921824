export interface ISort {
  name: string
  direction: 'ASC' | 'DESC'
}

export enum Currency {
  USD = 'USD',
}

export interface IIndex {
  id: number
  name: string
}
