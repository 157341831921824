import React, { useState } from 'react'
import { Layout, Menu, MenuProps } from 'antd'
import {
  HomeOutlined,
  UserOutlined,
  SwapOutlined,
  CreditCardOutlined,
  DatabaseOutlined,
} from '@ant-design/icons'
import cn from 'classnames'
import styles from './style.module.css'
import { useLocation, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import logo from '../../../../assets/images/logo.png'
import { useKeycloak } from '@react-keycloak/web'
import {
  P_SHOW_CUSTOMERS,
  P_SHOW_EXCHANGE_ORDERS,
  P_SHOW_ORDERS,
} from '../../../../utils/rbac/permissions'

const { Sider: AntdSider } = Layout

type MenuItem = Required<MenuProps>['items'][number]

function getItem(
  path: React.Key,
  label: React.ReactNode,
  icon?: React.ReactNode,
  children?: MenuItem[]
): MenuItem {
  return {
    key: path,
    icon,
    children,
    label,
  } as MenuItem
}

const Sider: React.FC = () => {
  const {
    keycloak: { hasRealmRole },
  } = useKeycloak()

  const { t } = useTranslation()
  const { pathname } = useLocation()
  const navigate = useNavigate()

  const [collapsed, setCollapsed] = useState(true)

  const items: MenuItem[] = [getItem('/', t('sider.home'), <HomeOutlined />)]

  if (hasRealmRole(P_SHOW_ORDERS)) {
    items.push(getItem('/order', t('sider.order'), <CreditCardOutlined />))
  }

  if (hasRealmRole(P_SHOW_EXCHANGE_ORDERS)) {
    items.push(
      getItem('/exchange-order', t('sider.exchange_order'), <SwapOutlined />)
    )
  }

  if (hasRealmRole(P_SHOW_CUSTOMERS)) {
    items.push(getItem('/customer', t('sider.customer'), <UserOutlined />))
  }

  items.push(getItem('/api-db', t('sider.api_db'), <DatabaseOutlined />))

  return (
    <AntdSider
      collapsible
      collapsed={collapsed}
      onCollapse={setCollapsed}
      className={cn(styles.sider)}
    >
      <div className={cn(styles.brandLogoContainer)}>
        <img
          className={cn(styles.brandLogo)}
          src={logo}
          alt="gift-cardy-logo"
        />
      </div>
      <Menu
        theme="dark"
        className={cn(styles.menu)}
        selectedKeys={[`/${pathname.split('/')[1]}`]}
        mode="inline"
        items={items}
        onSelect={(e) => {
          navigate(e.key)
        }}
      />
    </AntdSider>
  )
}

export default Sider
