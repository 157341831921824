import { Form, FormInstance, Input, Modal } from 'antd'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation, useQueryClient } from 'react-query'
import axios from 'axios'
import { IEditExchangeOrder } from '../../../../models/exchange-order'

interface IProps {
  orderId: string
  visible: boolean
  setVisible: (visible: boolean) => void
  note?: string
  data?: string
  refundReason?: string
}

const EditExchangeOrderModal: React.FC<IProps> = ({
  orderId,
  visible,
  setVisible,
  ...props
}) => {
  const { t } = useTranslation()

  const queryClient = useQueryClient()

  const formRef = React.useRef<FormInstance>(null)

  const mutation = useMutation({
    mutationFn: (data: IEditExchangeOrder) => {
      return axios.put(`/exchange-order/${orderId}`, data)
    },
    onSuccess() {
      queryClient.invalidateQueries({ queryKey: ['exchange-orders'] })
      queryClient.invalidateQueries({ queryKey: ['exchange-order', orderId] })
      setVisible(false)
    },
  })

  return (
    <Modal
      title={t('exchange_order.edit_exchange_order')}
      open={visible}
      onOk={() => {
        const data = formRef.current?.getFieldsValue()
        mutation.mutate({
          note: data['note'],
          rejectionReason: data['rejectionReason'],
        })
      }}
      onCancel={() => setVisible(false)}
      confirmLoading={mutation.isLoading}
    >
      <Form
        name="form_item_path"
        layout="vertical"
        ref={formRef}
        initialValues={props}
      >
        <Form.Item name="note" label={t('exchange_order.note')}>
          <Input.TextArea />
        </Form.Item>
        <Form.Item
          name="rejectionReason"
          label={t('exchange_order.rejection_reason')}
        >
          <Input.TextArea />
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default EditExchangeOrderModal
