import React from 'react'
import { Tag } from 'antd'
import { useTranslation } from 'react-i18next'
import { OrderStatus } from '../../../models/order'

interface IProps {
  status: OrderStatus
}

const OrderStatusTag: React.FC<IProps> = ({ status }) => {
  const { t } = useTranslation()

  let label = ''
  let color = ''

  switch (status) {
    case OrderStatus.PENDING:
      label = t('order.status_pending')
      color = 'default'
      break
    case OrderStatus.PARTIALLY_PAID:
      label = t('order.status_partially_paid')
      color = 'lime'
      break
    case OrderStatus.PAID:
      label = t('order.status_paid')
      color = 'gold'
      break
    case OrderStatus.PROCESSED:
      label = t('order.status_processed')
      color = 'green'
      break
    case OrderStatus.REFUNDED:
      label = t('order.status_refunded')
      color = 'cyan'
      break
  }

  return <Tag color={color}>{label}</Tag>
}

export default OrderStatusTag
