import React, { PropsWithChildren } from 'react'
import { Layout as AntdLayout } from 'antd'
import cn from 'classnames'
import styles from './style.module.css'
import Header from './header'
import Sider from './sider'

const { Content, Footer } = AntdLayout

const Layout: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <div
      style={{
        minHeight: '100vh',
        overflowY: 'hidden',
        overflowX: 'auto',
      }}
    >
      <AntdLayout className={cn(styles.layout)}>
        <Sider />
        <AntdLayout>
          <Header />
          <Content className={cn(styles.content)}>
            <Content className={cn(styles.innerContent)}>{children}</Content>
            <Footer className={cn(styles.footer)}>
              Gift-Cardy Dashboard ©2024
            </Footer>
          </Content>
        </AntdLayout>
      </AntdLayout>
    </div>
  )
}

export default Layout
