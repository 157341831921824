import React, { useState } from 'react'
import { Card, Button, Descriptions, Spin, Space, Table, Tag } from 'antd'
import {
  EditOutlined,
  CloseOutlined,
  DeleteOutlined,
  CheckOutlined,
} from '@ant-design/icons'
import styles from './style.module.css'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { useQuery } from 'react-query'
import axios from 'axios'
import dayjs from 'dayjs'
import { DATE_TIME_FORMAT } from '../../../utils/constants'
import { EyeOutlined } from '@ant-design/icons'
import {
  ExchangeOrderStatus,
  IExchangeOrderDetails,
  IExchangeOrderLine,
  IExchangeOrderLineProduct,
} from '../../../models/exchange-order'
import ExchangeOrderStatusTag from '../../../components/enums/exchange-order-status-tag'
import DeleteExchangeOrderModal from '../../../components/modals/exchnage-order/delete-exchange-order'
import AcceptExchangeOrderModal from '../../../components/modals/exchnage-order/accept-exchange-order'
import PayExchangeOrderModal from '../../../components/modals/exchnage-order/pay-exchange-order'
import EditExchangeOrderModal from '../../../components/modals/exchnage-order/edit-exchange-order'
import RejectExchangeOrderModal from '../../../components/modals/exchnage-order/reject-exchange-order'
import ExchangeOrderPaymentMethodTag from '../../../components/enums/exchange-order-payment-method-tag'
import ExchangeOrderProductDetailsModal from '../../../components/modals/exchnage-order/exchange-order-product-details'
import { useKeycloak } from '@react-keycloak/web'
import {
  P_ACCEPT_EXCHANGE_ORDER,
  P_DELETE_EXCHANGE_ORDER,
  P_PAY_EXCHANGE_ORDER,
  P_REJECT_EXCHANGE_ORDER,
  P_SHOW_EXCHANGE_ORDERS,
  P_UPDATE_EXCHANGE_ORDER,
} from '../../../utils/rbac/permissions'

const ExchangeOrderDetailsPage: React.FC = () => {
  const {
    keycloak: { hasRealmRole },
  } = useKeycloak()

  const navigate = useNavigate()

  if (!hasRealmRole(P_SHOW_EXCHANGE_ORDERS)) {
    navigate('/403')
  }

  const { t } = useTranslation()

  const { id } = useParams()

  const [acceptModalVisible, setAcceptModalVisible] = useState(false)
  const [rejectModalVisible, setRejectModalVisible] = useState(false)
  const [payModalVisible, setPayModalVisible] = useState(false)
  const [editModalVisible, setEditModalVisible] = useState(false)
  const [deleteModalVisible, setDeleteModalVisible] = useState(false)
  const [productDetailsModalVisible, setProductDetailsModalVisible] =
    useState(false)
  const [line, setLine] = useState<IExchangeOrderLine | null>(null)

  const query = useQuery({
    queryKey: ['exchange-order', id],
    queryFn: async () => {
      return axios.get<IExchangeOrderDetails>(`/exchange-order/${id}`)
    },
  })

  var data = query.data?.data

  return (
    <div>
      <AcceptExchangeOrderModal
        orderId={`${id}`}
        visible={acceptModalVisible}
        setVisible={setAcceptModalVisible}
      />
      <RejectExchangeOrderModal
        orderId={`${id}`}
        visible={rejectModalVisible}
        setVisible={setRejectModalVisible}
      />
      <PayExchangeOrderModal
        orderId={`${id}`}
        visible={payModalVisible}
        setVisible={setPayModalVisible}
      />
      <EditExchangeOrderModal
        orderId={`${id}`}
        visible={editModalVisible}
        setVisible={setEditModalVisible}
        {...data}
      />
      <DeleteExchangeOrderModal
        orderId={`${id}`}
        visible={deleteModalVisible}
        setVisible={setDeleteModalVisible}
      />
      <ExchangeOrderProductDetailsModal
        line={line ?? undefined}
        visible={productDetailsModalVisible}
        setVisible={setProductDetailsModalVisible}
      />
      <Card className={styles.headerCard} bodyStyle={{ padding: 0 }}>
        <div className={styles.headerCardTitle}>
          <h3>{t('exchange_order.details')}</h3>
          <div className={styles.headerCardTitleTrailing}>
            <Space>
              <Button
                type="default"
                icon={<CloseOutlined />}
                onClick={() => navigate(-1)}
              >
                {t('general.cancel')}
              </Button>
              {hasRealmRole(P_UPDATE_EXCHANGE_ORDER) && (
                <Button
                  type="primary"
                  icon={<EditOutlined />}
                  onClick={() => setEditModalVisible(true)}
                >
                  {t('general.edit')}
                </Button>
              )}
              {data?.status === ExchangeOrderStatus.PENDING &&
                hasRealmRole(P_ACCEPT_EXCHANGE_ORDER) && (
                  <Button
                    type="primary"
                    icon={<CheckOutlined />}
                    onClick={() => setAcceptModalVisible(true)}
                  >
                    {t('exchange_order.accept')}
                  </Button>
                )}
              {data?.status === ExchangeOrderStatus.PENDING &&
                hasRealmRole(P_REJECT_EXCHANGE_ORDER) && (
                  <Button
                    type="primary"
                    icon={<CloseOutlined />}
                    onClick={() => setRejectModalVisible(true)}
                  >
                    {t('exchange_order.reject')}
                  </Button>
                )}
              {data?.status === ExchangeOrderStatus.ACCEPTED &&
                hasRealmRole(P_PAY_EXCHANGE_ORDER) && (
                  <Button
                    type="primary"
                    icon={<CheckOutlined />}
                    onClick={() => setPayModalVisible(true)}
                  >
                    {t('exchange_order.pay')}
                  </Button>
                )}
              {hasRealmRole(P_DELETE_EXCHANGE_ORDER) && (
                <Button
                  type="primary"
                  danger
                  icon={<DeleteOutlined />}
                  onClick={() => setDeleteModalVisible(true)}
                >
                  {t('general.delete')}
                </Button>
              )}
            </Space>
          </div>
        </div>
      </Card>
      <Card bodyStyle={{ padding: 0 }}>
        <Spin tip={t('general.loading')} spinning={query.isFetching}>
          <Descriptions bordered column={1}>
            <Descriptions.Item label={t('general.id')}>
              {data?.id}
            </Descriptions.Item>
            <Descriptions.Item label={t('exchange_order.full_name')}>
              {data?.fullName}
            </Descriptions.Item>
            <Descriptions.Item label={t('exchange_order.email')}>
              <a href={`mailto:${data?.email}`}>{data?.email}</a>
            </Descriptions.Item>
            <Descriptions.Item label={t('exchange_order.gsm')}>
              {data?.gsm}
            </Descriptions.Item>
            <Descriptions.Item label={t('exchange_order.status')}>
              {data?.status && <ExchangeOrderStatusTag status={data?.status} />}
            </Descriptions.Item>
            <Descriptions.Item label={t('exchange_order.note')}>
              {data?.note}
            </Descriptions.Item>
            <Descriptions.Item label={t('exchange_order.rejection_reason')}>
              {data?.rejectionReason}
            </Descriptions.Item>
            <Descriptions.Item label={t('exchange_order.payment_method')}>
              {data?.paymentMethod && (
                <ExchangeOrderPaymentMethodTag method={data?.paymentMethod} />
              )}
            </Descriptions.Item>
            <Descriptions.Item label={t('exchange_order.crypto_method')}>
              {data?.cryptoMethod && <Tag>{data?.cryptoMethod}</Tag>}
            </Descriptions.Item>
            <Descriptions.Item label={t('exchange_order.products')}>
              <Table
                bordered
                columns={[
                  {
                    title: t('exchange_order.product_name'),
                    dataIndex: 'product',
                    render: (product: IExchangeOrderLineProduct) =>
                      `${product?.name} | ${product?.voucher}`,
                  },
                  {
                    title: t('exchange_order.product_price'),
                    dataIndex: 'product',
                    render: (product: IExchangeOrderLineProduct) => (
                      <ul style={{ padding: '0.5rem' }}>
                        <li>
                          <strong>Crypto Price: </strong>
                          {product.cryptoPrice}
                        </li>
                        <li>
                          <strong>PayPal Price: </strong>
                          {product.paypalPrice}
                        </li>
                        <li>
                          <strong>PerfectMoney Price: </strong>
                          {product.perfectMoneyPrice}
                        </li>
                        <li>
                          <strong>WebMoney Price: </strong>
                          {product.webMoneyPrice}
                        </li>
                      </ul>
                    ),
                  },
                  {
                    title: t('general.actions'),
                    render: (_, record) => (
                      <Space>
                        <Button
                          type="link"
                          icon={<EyeOutlined />}
                          onClick={() => {
                            setLine(record)
                            setProductDetailsModalVisible(true)
                          }}
                        >
                          {t('general.show')}
                        </Button>
                      </Space>
                    ),
                  },
                ]}
                dataSource={data?.lines ?? []}
                pagination={false}
              />
            </Descriptions.Item>
            <Descriptions.Item label={t('general.created_at')}>
              {data?.createdAt &&
                dayjs(data.createdAt).format(DATE_TIME_FORMAT)}
            </Descriptions.Item>
            <Descriptions.Item label={t('general.updated_at')}>
              {data?.updatedAt &&
                dayjs(data.updatedAt).format(DATE_TIME_FORMAT)}
            </Descriptions.Item>
          </Descriptions>
        </Spin>
      </Card>
    </div>
  )
}

export default ExchangeOrderDetailsPage
