import DashboardOutlet from './components/dashboard-outlet'
import ForbiddenPage from './pages/error/403'
import HomePage from './pages/home'
import NotFoundPage from './pages/error/404'
import { createBrowserRouter, Outlet, RouteObject } from 'react-router-dom'
import OrdersPage from './pages/order'
import OrderDetailsPage from './pages/order/details'
import CustomersPage from './pages/customer'
import ExchangeOrdersPage from './pages/exchange-order'
import ExchangeOrderDetailsPage from './pages/exchange-order/details'

const routes: RouteObject[] = [
  {
    element: <Outlet />,
    errorElement: <NotFoundPage />,
    children: [
      {
        path: '/',
        element: <DashboardOutlet />,
        children: [
          {
            path: '',
            element: <HomePage />,
          },
          {
            path: 'order',
            element: <Outlet />,
            children: [
              { path: '', element: <OrdersPage /> },
              { path: ':id', element: <OrderDetailsPage /> },
            ],
          },
          {
            path: 'exchange-order',
            element: <Outlet />,
            children: [
              { path: '', element: <ExchangeOrdersPage /> },
              { path: ':id', element: <ExchangeOrderDetailsPage /> },
            ],
          },
          {
            path: 'customer',
            element: <Outlet />,
            children: [{ path: '', element: <CustomersPage /> }],
          },
          {
            path: 'api-db',
            element: (
              <iframe
                title="API Database"
                src="https://api-db.mygiftcardy.com"
                width={'100%'}
                className="db-frame"
              />
            ),
          },
        ],
      },
      {
        path: '/404',
        element: <NotFoundPage />,
      },
      {
        path: '/403',
        element: <ForbiddenPage />,
      },
    ],
  },
]

const router = createBrowserRouter(routes)

export default router
