import StatisticsCards from '../../components/home/statistics-cards'
import { Col, Row } from 'antd'
import BestSellingProducts from '../../components/home/charts-card/best-selling-products'
import MostActiveCustomers from '../../components/home/charts-card/most-active-customers'
import OrdersCountTimelineDaily from '../../components/home/charts-card/orders-count-timeline-daily'
import OrdersCountTimelineMonthly from '../../components/home/charts-card/orders-count-timeline-monthly'
import OrdersCountTimelineYearly from '../../components/home/charts-card/orders-count-timeline-yearly'
import ExchangeOrdersCountTimelineDaily from '../../components/home/charts-card/exchange-orders-count-timeline-daily'
import ExchangeOrdersCountTimelineMonthly from '../../components/home/charts-card/exchange-orders-count-timeline-monthly'
import ExchangeOrdersCountTimelineYearly from '../../components/home/charts-card/exchange-orders-count-timeline-yearly'
import OrdersStatusesOverview from '../../components/home/charts-card/orders-statuses-overview'
import ExchangeOrdersStatusesOverview from '../../components/home/charts-card/exchange-orders-statuses-overview'
import { useKeycloak } from '@react-keycloak/web'
import { P_SHOW_STATISTICS } from '../../utils/rbac/permissions'
import logo from '../../assets/images/logo-colored.png'

const HomePage = () => {
  const {
    keycloak: { hasRealmRole },
  } = useKeycloak()

  if (!hasRealmRole(P_SHOW_STATISTICS)) {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '80vh',
        }}
      >
        <img src={logo} alt="gift-cardy-logo" height={100} />
      </div>
    )
  }

  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <StatisticsCards />
      </Col>

      <Col span={24}>
        <Row gutter={[16, 16]} align={'stretch'}>
          <Col span={12}>
            <BestSellingProducts />
          </Col>
          <Col span={12}>
            <MostActiveCustomers />
          </Col>
          <Col span={12}>
            <OrdersStatusesOverview />
          </Col>
          <Col span={12}>
            <ExchangeOrdersStatusesOverview />
          </Col>
          <Col span={24}>
            <OrdersCountTimelineDaily />
          </Col>
          <Col span={12}>
            <OrdersCountTimelineMonthly />
          </Col>
          <Col span={12}>
            <OrdersCountTimelineYearly />
          </Col>
          <Col span={24}>
            <ExchangeOrdersCountTimelineDaily />
          </Col>
          <Col span={12}>
            <ExchangeOrdersCountTimelineMonthly />
          </Col>
          <Col span={12}>
            <ExchangeOrdersCountTimelineYearly />
          </Col>
        </Row>
      </Col>
    </Row>
  )
}
export default HomePage
