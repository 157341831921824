import { Line } from 'react-chartjs-2'
import { Card, Spin } from 'antd'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ITimelineChartPoint, TimelineChartType } from '../../../../models/home'
import { useQuery } from 'react-query'
import axios from 'axios'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js'
import dayjs from 'dayjs'
import { DATE_FORMAT } from '../../../../utils/constants'
import { ExchangeOrderStatus } from '../../../../models/exchange-order'

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
)

const ExchangeOrdersCountTimelineMonthly: React.FC = () => {
  const { t } = useTranslation()

  const [fromDate, setFromDate] = useState<string>(
    dayjs(new Date()).format(DATE_FORMAT)
  )
  const [toDate, setToDate] = useState<string>(
    dayjs(new Date()).format(DATE_FORMAT)
  )

  const [data, setData] = useState<any>()

  useEffect(() => {
    const now = dayjs(new Date())
    setFromDate(now.subtract(18, 'month').format(DATE_FORMAT))
    setToDate(now.format(DATE_FORMAT))
  }, [])

  const query = useQuery({
    queryKey: [
      'statistics-exchange-orders-count-timeline-monthly',
      fromDate,
      toDate,
    ],
    queryFn: async () => {
      const allOrders = await axios.get<ITimelineChartPoint[]>(
        '/statistics/exchange-orders-count-timeline',
        {
          params: {
            type: TimelineChartType.MONTHLY,
            fromDate,
            toDate,
          },
        }
      )

      const pendingOrders = await axios.get<ITimelineChartPoint[]>(
        '/statistics/exchange-orders-count-timeline',
        {
          params: {
            type: TimelineChartType.MONTHLY,
            fromDate,
            toDate,
            status: ExchangeOrderStatus.PENDING,
          },
        }
      )

      const acceptedOrders = await axios.get<ITimelineChartPoint[]>(
        '/statistics/exchange-orders-count-timeline',
        {
          params: {
            type: TimelineChartType.MONTHLY,
            fromDate,
            toDate,
            status: ExchangeOrderStatus.ACCEPTED,
          },
        }
      )

      const rejectedOrders = await axios.get<ITimelineChartPoint[]>(
        '/statistics/exchange-orders-count-timeline',
        {
          params: {
            type: TimelineChartType.MONTHLY,
            fromDate,
            toDate,
            status: ExchangeOrderStatus.REJECTED,
          },
        }
      )

      const paidOrders = await axios.get<ITimelineChartPoint[]>(
        '/statistics/exchange-orders-count-timeline',
        {
          params: {
            type: TimelineChartType.MONTHLY,
            fromDate,
            toDate,
            status: ExchangeOrderStatus.PAID,
          },
        }
      )

      return {
        allOrders: allOrders.data,
        pendingOrders: pendingOrders.data,
        acceptedOrders: acceptedOrders.data,
        rejectedOrders: rejectedOrders.data,
        paidOrders: paidOrders.data,
      }
    },
    onSuccess(data) {
      const labels = data.allOrders.map((p) => dayjs(p.date).format('YYYY-MM'))
      const allOrders = data.allOrders.map((p) => p.value)
      const pendingOrders = data.pendingOrders.map((p) => p.value)
      const acceptedOrders = data.acceptedOrders.map((p) => p.value)
      const rejectedOrders = data.rejectedOrders.map((p) => p.value)
      const paidOrders = data.paidOrders.map((p) => p.value)

      const chartData = {
        labels: labels,
        datasets: [
          {
            label: t('home.all'),
            data: allOrders,
            backgroundColor: '#12ccaa',
            borderColor: '#12ccaa',
          },
          {
            label: t('exchange_order.status_pending'),
            data: pendingOrders,
            backgroundColor: '#d9d8ff',
            borderColor: '#d9d8ff',
          },
          {
            label: t('exchange_order.status_accepted'),
            data: acceptedOrders,
            backgroundColor: '#5cb176',
            borderColor: '#5cb176',
          },
          {
            label: t('exchange_order.status_rejected'),
            data: rejectedOrders,
            backgroundColor: '#ee5e52',
            borderColor: '#ee5e52',
          },
          {
            label: t('exchange_order.status_paid'),
            data: paidOrders,
            backgroundColor: '#66b7f1',
            borderColor: '#66b7f1',
          },
        ],
      }
      setData(chartData)
    },
  })

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'top' as const,
      },
    },
  }

  return (
    <Spin spinning={query.isLoading}>
      <Card
        title={`${t('home.exchange_orders_count_timeline')} (${t(
          'home.monthly'
        )})`}
        headStyle={{
          textAlign: 'center',
        }}
        bodyStyle={{
          height: '28rem',
          width: '100%',
        }}
      >
        {data && (
          <Line
            data={data}
            options={options}
            style={{
              width: '100%',
            }}
          />
        )}
      </Card>
    </Spin>
  )
}

export default ExchangeOrdersCountTimelineMonthly
