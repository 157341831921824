import { Tag } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { ExchangeOrderPaymentMethod } from '../../../models/exchange-order'

interface IProps {
  method: ExchangeOrderPaymentMethod
}

const ExchangeOrderPaymentMethodTag: React.FC<IProps> = ({ method }) => {
  const { t } = useTranslation()

  let label = ''

  switch (method) {
    case ExchangeOrderPaymentMethod.CRYPTO:
      label = t('exchange_order.payment_method_crypto')
      break
    case ExchangeOrderPaymentMethod.PAYPAL:
      label = t('exchange_order.payment_method_paypal')
      break
    case ExchangeOrderPaymentMethod.PERFECT_MONEY:
      label = t('exchange_order.payment_method_perfect_money')
      break
    case ExchangeOrderPaymentMethod.WEB_MONEY:
      label = t('exchange_order.payment_method_web_money')
      break
  }

  return <Tag>{label}</Tag>
}

export default ExchangeOrderPaymentMethodTag
