import { Form, FormInstance, Input, Modal } from 'antd'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { IEditOrder } from '../../../../models/order'
import { useMutation, useQueryClient } from 'react-query'
import axios from 'axios'

interface IProps {
  orderId: string
  visible: boolean
  setVisible: (visible: boolean) => void
  note?: string
  data?: string
  refundReason?: string
}

const EditOrderModal: React.FC<IProps> = ({
  orderId,
  visible,
  setVisible,
  ...props
}) => {
  const { t } = useTranslation()

  const queryClient = useQueryClient()

  const formRef = React.useRef<FormInstance>(null)

  const mutation = useMutation({
    mutationFn: (data: IEditOrder) => {
      return axios.put(`/order/${orderId}`, data)
    },
    onSuccess() {
      queryClient.invalidateQueries({ queryKey: ['orders'] })
      queryClient.invalidateQueries({ queryKey: ['order', orderId] })
      setVisible(false)
    },
  })

  return (
    <Modal
      title={t('order.edit_order')}
      open={visible}
      onOk={() => {
        const data = formRef.current?.getFieldsValue()
        mutation.mutate({
          note: data['note'],
          data: data['data'],
          refundReason: data['refundReason'],
        })
      }}
      onCancel={() => setVisible(false)}
      confirmLoading={mutation.isLoading}
    >
      <Form
        name="form_item_path"
        layout="vertical"
        ref={formRef}
        initialValues={props}
      >
        <Form.Item name="note" label={t('order.note')}>
          <Input.TextArea />
        </Form.Item>
        <Form.Item name="data" label={t('order.data')}>
          <Input.TextArea />
        </Form.Item>
        <Form.Item name="refundReason" label={t('order.refund_reason')}>
          <Input.TextArea />
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default EditOrderModal
