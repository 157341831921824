import { Bar } from 'react-chartjs-2'
import { Card, Spin } from 'antd'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IMostActiveCustomer } from '../../../../models/home'
import { useQuery } from 'react-query'
import axios from 'axios'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js'

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)

const MostActiveCustomers: React.FC = () => {
  const { t } = useTranslation()

  const [data, setData] = useState<any>()

  const query = useQuery({
    queryKey: ['statistics-most-active-customers'],
    queryFn: async () => {
      return axios.get<IMostActiveCustomer[]>(
        '/statistics/most-active-customers'
      )
    },
    onSuccess(data) {
      const products = data.data ?? []

      const labels = products.map((c) => `${c.fullName}\n${c.email}`)
      const ordersCounts = products.map((c) => c.ordersCount)

      const chartData = {
        labels: labels,
        datasets: [
          {
            label: t('home.orders_count'),
            data: ordersCounts,
            backgroundColor: '#12ccaa',
          },
        ],
      }
      setData(chartData)
    },
  })

  const options = {
    responsive: true,
  }

  return (
    <Spin spinning={query.isLoading}>
      <Card
        title={t('home.most_active_customers')}
        headStyle={{
          textAlign: 'center',
        }}
        bodyStyle={{
          height: '20rem',
        }}
      >
        {data && <Bar data={data} options={options} />}
      </Card>
    </Spin>
  )
}

export default MostActiveCustomers
