import React from 'react'
import { Layout, theme } from 'antd'
import cn from 'classnames'
import Breadcrumb from './breadcrumb'
import UserSettings from './user-settings'
import styles from './style.module.css'
import LanguageSelect from './language-select'

const { Header: AntdHeader } = Layout

const Header: React.FC = () => {
  const {
    token: { colorBgContainer },
  } = theme.useToken()

  return (
    <AntdHeader
      style={{ background: colorBgContainer }}
      className={cn(styles.header)}
    >
      <Breadcrumb />
      <div className={cn(styles.trailing)}>
        <LanguageSelect />
        <UserSettings />
      </div>
    </AntdHeader>
  )
}

export default Header
