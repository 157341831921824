import React from 'react'
import { Breadcrumb as AntdBreadcrumb } from 'antd'
import { Link, useLocation } from 'react-router-dom'
import { HomeOutlined } from '@ant-design/icons'
import cn from 'classnames'
import styles from './style.module.css'
import { useTranslation } from 'react-i18next'

const Breadcrumb: React.FC = () => {
  const { t } = useTranslation()
  const { pathname } = useLocation()
  const pathSnippets = pathname.split('/').filter((i) => i)

  const extraBreadcrumbItems = pathSnippets.map((path, index) => {
    const url = `/${pathSnippets.slice(0, index + 1).join('/')}`
    return {
      key: url,
      title: (
        <Link to={url}>
          {isNaN(+path) && index !== 1
            ? t(`breadcrumb.${path.replaceAll('-', '_')}`)
            : path}
        </Link>
      ),
    }
  })

  const breadcrumbItems = [
    {
      title: (
        <Link to="/">
          <HomeOutlined className={cn(styles.homeIcon)} />
          <span>{t('breadcrumb.home')}</span>
        </Link>
      ),
      key: 'home',
    },
  ].concat(extraBreadcrumbItems)

  return (
    <div className={cn(styles.container)}>
      <AntdBreadcrumb items={breadcrumbItems} />
    </div>
  )
}

export default Breadcrumb
